import React from "react"
import { getSubscriptions, Unsubscribe } from "../../services/api";
import Loading from "../common/loading"
import { Link } from "gatsby"

import "./manage-alerts.scss"

class ManageAlertsForm extends React.Component {
  constructor(){
    super();
    this.state = {
      subscriptions: null,
      loading: true,
      unsubscribe_loading: [],//stroes all ids of loading subscriptions
    }
    this.unsubscribe = this.unsubscribe.bind(this);
  }


  componentDidMount() {
    getSubscriptions().then(response =>{
      this.setState({subscriptions : response, loading: false})
    })
  }

  unsubscribe (id, hash) {
    this.setState((prevState) =>({unsubscribe_loading: [...prevState.unsubscribe_loading, id]}));
    Unsubscribe(id, hash).then( response=>{
      if(response.Success){
        this.setState((prevState)=>({
          subscriptions : prevState.subscriptions.filter(x => x.id !== id),
          unsubscribe_loading: prevState.subscriptions.filter(x => x !== id),
        }))
      }
    })
  }

  showSubscriptions = (subscriptions) => {
    if(this.state.loading ){
      return (
        <div className="loading">
          <Loading/>
        </div>
      )
    }else if(!subscriptions || subscriptions.length === 0){
      return "No subscriptions for alerts"
    }

    return (
    <table className="subscriptions-table">
      <thead>
        <tr>
          <th>E-mail</th>
          <th>Frequency</th>
          <th>Type</th>
          <th>Filters</th>
          <th>Countries</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {subscriptions.map((subscription, index) => 
        <tr key={index}>
          <td>
            <span className="email">{subscription.email}</span>
          </td>
          <td>
            <span className="frequency">{subscription.frequency}</span>
          </td>
          <td>
            <span className="type">{subscription.type}</span>
          </td>
          <td>
            <div className="all-filters">
              {Object.keys(subscription.filters).map((filterType, index) => {
                if(subscription.filters[filterType] && subscription.filters[filterType].length !== 0){
                  return (
                  <div className="filter-group" key={index}>
                    <span className="filter-type">{filterType == "incidentType" ? "Type" : filterType}</span>
                    <div className="filters">
                      {subscription.filters[filterType].map((filter, index) =>{
                        if(index === subscription.filters[filterType].length-1){
                          return (<span className="filter" key={index}>{filter}</span>)
                        }else{
                          return (<span className="filter" key={index}>{filter}, </span>)
                        }
                      }
                      )}
                    </div>
                  </div>)
                }
              })}
            </div>
          </td>
          <td>
            <div className="countries">{
              subscription.countries.map((country, index) => {
                if(index === subscription.countries.length-1){
                  return (<span className="country" key={index}>{country}</span>)
                }else{
                  return (<span className="country" key={index}>{country}, </span>)
                }
              })
            }</div>
          </td>
          <td>
            <div className="edit-wrapper">
              <Link to={"/edit-alert/"+subscription.id} className="icon admin-edit"></Link>
              {this.state.unsubscribe_loading.includes(subscription.id) ? <Loading/> : 
              <a onClick={() =>this.unsubscribe(subscription.id, subscription.hash)}>
                Unsubscribe
              </a>}
            </div>
          </td>
        </tr>    
      )}
      </tbody>
    </table>
    )
  }

	render() {
		return (
			<div className="container">
        {this.showSubscriptions(this.state.subscriptions)}
      </div>
		);
	}
}

export default ManageAlertsForm