import React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import ManageAlertsForm from "../components/account/manage-alerts"


const ManageAlerts = () => {
	return (
    <Layout>
      <Seo title="PostNL Early Warning System Map" />
      <ManageAlertsForm/>
      
    </Layout>
  )
}
  
export default ManageAlerts